import React, { useContext } from "react";
import { ThemeContext } from "../../../context/themeContext";
import Particles from "react-tsparticles";

const ParticlesBackground = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Particles
      options={{
        fullScreen: {
          enable: true,
          zIndex: 0,
        },
        particles: {
          number: {
            value: window.screen.width < 768 ? 28 : 12,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          color: {
            value: theme.type === 'dark' ? "#ffffff" : "#000",
            animation: {
              enable: true,
              speed: 0,
              sync: true,
            },
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
            },
            polygon: {
              nb_sides: 6,
            },
          },
          opacity: {
            value: 0,
            random: false,
            anim: {
              enable: false,
              speed: 2,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: window.screen.width < 768 ? 12 : 10,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: window.screen.width < 768 ? 200 : 350,
            color: theme.type === 'dark' ? "#ffffff" : "#000",
            opacity: window.screen.width < 768 ? .15 : .08,
            width: 1,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        life: {
          duration: {
            sync: false,
            value: 2,
          },
          count: 0,
          delay: {
            random: {
              enable: true,
              minimumValue: 0.1,
            },
            value: 1,
          },
        },
        retina_detect: true,
        fps_limit: 60,
      }}
      className="particles-container"
    />
  );
};

export default ParticlesBackground;
