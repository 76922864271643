import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeContext } from "./context/themeContext";
import LoadingPage from "./components/UI/loading-page/LoadingPage";
import Container from "./styles/App.styled";
import Landing from "./pages/Landing";
import Links from "./pages/Links";
import "./styles/globals.css";
import { LoadingContext } from "./context/loadingContext";

const App = () => {
  const { theme } = useContext(ThemeContext);
  const { loading, setLoading } = useContext(LoadingContext);

  // Removes loading screen after component mount
  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {/* LOADING SCREEN */}
        {loading && <LoadingPage />}

        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Landing />}></Route>
            <Route path="/links" element={<Links />}></Route>
          </Routes>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
};

export default App;