import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeContextProvider } from "./context/themeContext";
import { LoadingContextProvider } from "./context/loadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoadingContextProvider>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </LoadingContextProvider>
  </React.StrictMode>
);
