import React from 'react'
import Logo from "./logo.jpg";

const Image = () => {
  return (
    <div className="image">
      <img
        src={Logo}
        alt="Osama Haj Ali | Full Stack Web Developer | Portfolio Logo"
        draggable={false}
      />
    </div>
  )
}

export default Image