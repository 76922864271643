import React, { useRef } from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import Projects from "../components/projects/Projects";
import Contact from "../components/contact/Contact";
import ParticlesBackground from "../components/UI/particles/Particles";

const Landing = () => {
  const homeRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();

  return (
    <>
      {/* PAGE SECTIONS */}
      <Header
        homeRef={homeRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
      />
      <Home
        homeRef={homeRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
      />
      <Projects projectsRef={projectsRef} />
      <Contact contactRef={contactRef} />

      {/* BACKGROUND PARTICLES */}
      <ParticlesBackground />
    </>
  );
};

export default Landing;