import styled from 'styled-components'

const Container = styled.main`
    font-family: 'Exo', sans-serif;
    position: relative;
    max-width: 100vw;
    min-width: 100vw;
    color: ${({theme}) => theme.colors.primary};
`

export default Container