import React from "react";
import Container from "../styles/Links.styled";
import Buttons from "../components/links/Buttons";
import Image from "../components/links/Image";
import Icons from "../components/links/Icons";
import BackgroundImage from "../components/links/background.jpg";

const Links = () => {
  return (
    <Container>
      <div className="background-image">
        <img src={BackgroundImage} alt="Osama Hajj Ali Portfolio Links Page Background" />
      </div>
      <div className="overlay"></div>
      <div className="content">
        <Image />
        <h1 className="name">Osama Haj-Ali</h1>
        <p className="about">Full Stack Web Developer</p>
        <Buttons />
        <Icons />
      </div>
    </Container>
  );
};

export default Links;
