import styled from "styled-components";

const Container = styled.main`
  position: relative;
  font-family: "Work Sans", sans-serif;
  color: #fff;
  width: 100vw;
  min-height: 100vh;
  background-color: #191F27;
  // Change to overflow-x: hidden if content doesn't fit screen height
  overflow: hidden;

  .background-image{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h1, p{
    letter-spacing: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .33);
    z-index: 2;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    .image {
      border-radius: 50%;
      overflow: hidden;
      width: 125px;
      height: 125px;
      margin: 4rem 0 0.75rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-size: 22px;
      font-weight: 600;
    }

    .about {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 1rem;
    }

    .link-buttons {
      width: 100%;
      max-width: 500px;

      a {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        color: #fff;
        padding: 1rem;
        margin-top: 1.25rem;
        border-radius: 30px;
        border: 2px solid #fcfcfc;
        transition: all 0.2s ease;

        img {
          position: absolute;
          right: 2rem;
          top: 1.23rem;
          opacity: 0;
          color: #000;
          width: 14px;
        }

        p,
        img {
          font-size: 14px;
          font-weight: 500;
          transition: all 0.2s ease;
        }

        :hover {
          background-color: #fcfcfc;

          p {
            color: #000;
          }
          img {
            opacity: 1;
          }
        }
      }
    }

    .icons {
      display: flex;
      align-items: flex-end;
      margin-top: 2.25rem;

      a {
        margin: 0 0.65rem;

        img {
          width: 24px;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .content {
      .image {
        width: 100px;
        height: 100px;
        margin: 3.5rem 0 0.75rem;
      }

      .name {
        font-size: 20px;
      }

      .about {
        font-size: 12px;
        margin: 0 0 0.75rem;
      }

      .link-buttons {
        max-width: 400px;

        a {
          padding: 0.75rem;
          margin-top: 0.75rem;

          img {
            right: 1.5rem;
            top: 0.975rem;
            width: 12px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .icons {
        margin-top: 2rem;

        a {
          margin: 0 0.55rem;

          img {
            width: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .content {
      padding: 0 .75rem;

      p {
        line-height: 22px;
      }
      .image {
        width: 90px;
        height: 90px;
        margin: 3rem 0 0.75rem;
      }
    }
  }
`;

export default Container;