import React, { useContext } from "react";
import Upload from "./upload.svg";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../context/loadingContext";

const Buttons = () => {
  return (
    <div className="link-buttons">
      <Button label="GitHub" url="https://github.com/Osama-NA" />
      <Button label="Portfolio" url="" />
      <Button label="SpeekCode" url="https://www.instagram.com/speekcode/" />
      <Button
        label="Submit your feedback"
        url="https://yourfeedbacks.netlify.app/"
      />
    </div>
  );
};

const Button = ({ label, url }) => {
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const goToPortfolio = () => {
    setLoading(true);
    navigate("/");
  };

  return (
    <a
      href={url}
      target={url === "" ? "" : "_blank"}
      rel="noopener noreferrer"
      onClick={() => (url === "" ? goToPortfolio() : "")}
    >
      <p>{label}</p>
      <img src={Upload} alt="Upload icon" />
    </a>
  );
};

export default Buttons;
